<template>
  <div id="wtc">
    <p v-if="description" v-html="description"></p>

    <ul v-if="school_id === '8167'" class="nav nav-pills school-navigation-pills">
      <li><a href="/culture">Culture</a></li>
      <li><a href="/">Bear Ambassadors</a></li>
      <li><a href="/academic-year-interns">Interns</a></li>
      <li><a href="/summer-student-staff">Summer Student Staff</a></li>
    </ul>

    <div style="display: flex; flex-direction: row; justify-content: space-between; align-content: center; align-items: center; margin-bottom: 10px; padding: 0 10px;">
      <a href="#" @click.prevent="filter()">Clear Filters</a>
      <div>
      <input type="text" v-model="searchString" @input="filter('filterByText')" @keyup.enter="filter('filterByText')" placeholder="Search Ambassadors">
<!--      <button @click="filter('filterByText')">Search</button>-->
      </div>
    </div>

    <div class="filters">
      <div class="filter">
        <multiselect
            ref="studies_dropdown"
            placeholder="Studies"
            v-model="study"
            :options="studies"
            @input="filter('study')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :maxHeight=500></multiselect>
      </div>
      <div class="filter">
        <multiselect
            placeholder="State"
            v-model="state"
            :options="states"
            @input="filter('state')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :maxHeight=500></multiselect>
      </div>
      <div class="filter" v-if="school_id === '8167'">
        <multiselect
            placeholder="Division"
            v-model="wustl_division"
            :options="wustl_divisions"
            @input="filter('wustl_division')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :maxHeight=500></multiselect>
      </div>
      <div class="filter" v-if="school_id === '8167'">
        <multiselect
            placeholder="Program"
            v-model="wustl_program"
            :options="wustl_programs"
            @input="filter('wustl_program')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :maxHeight=500></multiselect>
      </div>
      <div class="filter" v-if="school_id !== '8167'">
        <multiselect
            placeholder="Activities"
            v-model="activity"
            :options="activities"
            @input="filter('activity')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :maxHeight=500></multiselect>
      </div>
      <div class="filter" v-if="school_id !== '8167'">
        <multiselect
            placeholder="Passions"
            v-model="passion"
            :options="passions"
            @input="filter('passion')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :maxHeight=500></multiselect>
      </div>
    </div>

    <isotope class="ambassador-wrapper" ref="wtc" :options='option' :list="ambassadors"
             v-images-loaded:on.progress="layout">
      <div class="wtc-grid" v-for="ambassador in ambassadors" :key="ambassador.id">
        <img :src="ambassador.image" alt="">
        <h3><a :href="profile_url(ambassador)">{{ ambassador.fname }} {{ ambassador.lname.charAt(0) }}</a></h3>
        <div class="wtc-hometown">
          <b>Hometown: </b> {{ ambassador.location }}
        </div>
        <div class="wtc-major">
<!--          <eva-icon style="margin-right: 5px;" width="20" fill="" height="20" name="book-open"></eva-icon>-->
          <b>Major:</b>&nbsp;{{ ambassador.major }}
        </div>
        <div v-if="ambassador.major2" class="wtc-major2">
<!--          <eva-icon style="margin-right: 5px;" width="20" fill="" height="20" name="book-open"></eva-icon>-->
          <b>2nd Major:</b>&nbsp;{{ ambassador.major2 }}
        </div>
        <div class="wtc-gradyear">
          Class of {{ ambassador.gradyear_college }}
        </div>
<!--        <p v-if="ambassador.wustl_division">{{ ambassador.wustl_division }}</p>-->
<!--        <p v-if="ambassador.wustl_program">{{ ambassador.wustl_program }}</p>-->
      </div>
    </isotope>

  </div>
</template>

<script>
import Ambassador from './Ambassador'

import isotope from "vueisotope";
import imagesLoaded from 'vue-images-loaded'
import Multiselect from 'vue-multiselect'

export default {
  props: {
    description: {
      type: String
    },
    ambassadors: {
      type: Array
    },
    school_id: {
      type: String
    }
  },
  directives: {
    imagesLoaded
  },
  data() {
    return {
      study: '',
      city: '',
      state: '',
      activity: '',
      passion: '',
      wustl_division: '',
      wustl_program: '',
      searchString: '',
      option: {
        getFilterData: {
          study: (el) => {
            // console.log(this.value)
            return el.major === this.study
          },
          city: (el) => {
            // console.log(this.city)
            return el.city === this.city
          },
          state: (el) => {
            // console.log(this.city)
            return el.state === this.state
          },
          wustl_division: (el) => {
            // console.log(this.city)
            return el.wustl_division === this.wustl_division
          },
          wustl_program: (el) => {
            // console.log(this.city)
            return el.wustl_program === this.wustl_program
          },
          activity: (el) => {
            // console.log(this.city)
            return el.activity_list.includes(this.activity)
          },
          passion: (el) => {
            // console.log(this.city)
            return el.passion_list.includes(this.passion)
          },
          filterByText: (el) => {
            return el.fname.toLowerCase().includes(this.searchString.toLowerCase());
          }
        }
      },
    }
  },
  components: {
    Ambassador,
    isotope,
    Multiselect
  },
  computed: {
    studies() {
      return this.ambassadors.map(x => x.major).filter(onlyUnique).sort()
    },
    cities() {
      return this.ambassadors.map(x => x.city).filter(onlyUnique).sort()
    },
    states() {
      return this.ambassadors.map(x => x.state).filter(onlyUnique).sort()
    },
    wustl_divisions() {
      return this.ambassadors.map(x => x.wustl_division).filter(onlyUnique).sort()
    },
    wustl_programs() {
      return this.ambassadors.map(x => x.wustl_program).filter(onlyUnique).sort()
    },
    activities() {
      return this.ambassadors.flatMap(x => x.activity_list).filter(onlyUnique).sort()
    },
    passions() {
      return this.ambassadors.flatMap(x => x.passion_list).filter(onlyUnique).sort()
    }
  },
  methods: {
    layout() {
      this.$refs.wtc.layout('masonry');
    },
    profile_url(a) {
      return `/profile/${a.username}`
    },
    filter(key) {
      let keys = ['study', 'state', 'city', 'activity', 'passion', 'wustl_division', 'wustl_program']
      keys.forEach(key_value => {
        if (key_value === key) { return; }
        this[key_value] = null;
      })
      this.$refs.wtc.filter(key);
    },
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
//#wtc {
//  all: unset;
//}

.ambassador-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  font-size: 75%;
}

.wtc-grid {
  /*background: #eee;*/
  //padding: 10px;
  //flex: 25%;
  margin: 10px 0;
  //width: 97%;
  min-height: 470px;
  //height: min-content;
  font-family: 'Open Sans', Helvetica, sans-serif;
  text-align: center;
}

@media (min-width: 769px) {
  .wtc-grid {
    width: 25%;
  }
}

.wtc-grid img {
  width: 95%;
  height: 300px;
  object-fit: cover;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.filter {
  flex: 1;
  padding: 0 10px;
  //width: 23%;
  //padding-right: 1%;
}
</style>

<style>
.multiselect__element {
  margin-left: 0 !important;
  padding: 0 !important;
}

.multiselect__option {
  padding: 8px !important;
  min-height: 20px !important;
  line-height: 14px !important;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-family: inherit;
  font-size: 12px;
  touch-action: manipulation;
}

body:not(.touch) a, body:not(.touch) b, body:not(.touch) button, body:not(.touch) div, body:not(.touch) em, body:not(.touch) h1, body:not(.touch) header, body:not(.touch) i, body:not(.touch) img, body:not(.touch) input, body:not(.touch) nav, body:not(.touch) p, body:not(.touch) span, body:not(.touch) strong {
  transition: background-color .3s ease, border-color .3s ease, color .3s ease, margin .3s ease, min-height .3s ease, max-width .3s ease, opacity .3s ease, text-shadow .3s ease, width .3s ease
}
</style>