<template>
 <div>
   <img :src="ambassador.image" alt=""><br>
    {{ ambassador.fname }} {{ ambassador.lname.charAt(0) }}
 </div>
</template>

<script>
 export default {
   props: {
     ambassador: {
       type: Object
     },
   },
 }
</script>

<style scoped>
  .ambassador-wrapper {
    background: #DDDDDD;
    padding: 1.5em 1%;

    display: inline-block;
    overflow: hidden;
    /*padding-top: 1.5em;*/
    /*padding-bottom: 1.5em;*/
    /*padding-right: 2%;*/
    position: relative;
    vertical-align: top;
  }

  img {
    width: auto;
    height: 100%;
    /*width: 300px;*/
    /*height: 300px;*/
    object-fit: cover;
  }
</style>