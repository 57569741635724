/* eslint no-console:0 */

// import Vue from 'vue'
// var Turbolinks = require("turbolinks")
// Turbolinks.start()

// import "../css/application.css"
import Vue from 'vue/dist/vue.esm'
// import store from '../registration_store'
// import Vuex from 'vuex'
// Vue.use(Vuex)

// import TurbolinksAdapter from 'vue-turbolinks'
// Vue.use(TurbolinksAdapter)
const event = (typeof Turbolinks == "object" && Turbolinks.supported) ? "turbolinks:load" : "DOMContentLoaded"
// const event = "DOMContentLoaded"

import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);

import EvaIcons from 'vue-eva-icons'
Vue.use(EvaIcons)

import underscore from 'vue-underscore';
Vue.use(underscore);

import MaskedInput from 'vue-text-mask'
Vue.use(MaskedInput);

// import VeeValidate from'vee-validate'
// Vue.use(VeeValidate);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip);

import Ambassadors from '../components/microsite/Ambassadors.vue'
Vue.component('ambassadors', Ambassadors)

document.addEventListener(event, () => {
  const app = new Vue({
    el: '[data-behavior="vue"]',
  })
})
